<template>
  <Loading v-if="loading" :loading="loading" />
  <div class="wrapper" v-else>
    <div class="listWrap">
      <div class="detail-name">
        <div class="left">{{$t('taskstatus.tasklist')}}</div>
        <div class="right">
          <i18n-t keypath="taskstatus.history" tag="div">
            <template #mine>
              <span class="link" @click="$router.push('/taskhub/history')">{{$t('taskstatus.mine')}}</span>
            </template>
          </i18n-t>
        </div>
      </div>
      <div class="reports-list">
        <div class="report" v-for="item in state.tasklist" :key="item.id">
          <div class="report-title">
            <div class="report-title-name">
              <copy-icon
                style="vertical-align: middle;width:20px;"
                name="name"
                :copyText="item.filename || item.url"
              />
            </div>
            <div @click="handleItemClick(item)" class="report-title-status" :class="{ 'pending': item.status !== 'reported' && !item.status.includes('failed') }">
              <p style="max-width: 355px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.filename || item.url}}</p>
               <img :src="getStatusIconSrc(item.status)" />
            </div>
          </div>
          <el-collapse-transition>
            <div v-if="state.currentId === item.id" class="report-detail">
              <analyse-summary
                v-if="state.switched"
                :taskInfo="taskInfo"
                :summary="summary"
                :progress="progress"
                :isTaskList="true"
                :onlyIncludeStatic="onlyIncludeStatic"
                :isRunning="isRunning"
              />
              <Loading v-if="!state.switched" :loading="!state.switched" />
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
    <div class="content">
      <sand-el-tab
        class="report-tab"
        :data="elTableData"
        @currentTab="handleTabChange"
        :completed="completed"
        :taskid="currentId"
      >
      </sand-el-tab>
      <keep-alive>
        <component
          class="contentWrapper"
          :is="tabs[currentModule]"
          :taskInfo="taskInfo"
          :summary="summary"
          :taskid="currentId"
          :progress="progress"
          :state="state"
          :onlyIncludeStatic="onlyIncludeStatic"
          :showProgress = "showProgress"
        >
        </component>
      </keep-alive>
    </div>
  </div>
</template>

<script setup>
import analyseSummary from '../components/analyseSummary.vue'
import sandElTab from '@/components/report/sandElTab'
import analyse from '../detail/analyse'

import {
  getTaskUserSingle,
  getTaskReportOverview,
  getTaskReportTags,
  getTaskReportNetwork,
  getTaskReportBehaviorProcess,
  getTaskReportShots
  // getTaskReportZserver,
  // getTaskReportZserverInfo
} from 'services/report'

import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs, computed, watchEffect, watch } from 'vue'
import eventDesc from 'assets/markdown/event_description.json'
import { t, isZh } from 'app/i18n'

const tabs = {
  analyse
}

const route = useRoute()
const router = useRouter()
const store = useStore()
const state = reactive({
  taskInfo: null,
  summary: {},
  staticsearchskill: {},
  machinelearning: {},
  behaviorfeature: {},
  behaviorscreenshot: {},
  networkCount: {},
  progressanalyse: {},
  loading: true,
  category: 'file',
  taskOS: '',
  events: eventDesc.category,
  currentModule: route.params.type || 'analyse',
  tasklist: [],
  currentId: '',
  currentItem: {},
  flag: false,
  timer: null,
  showProgress: false,
  switched: false
})

let taskId = route.query.tasklist.split(',')[0]

const { taskInfo, summary, loading, currentModule, showProgress, currentId } = toRefs(state)

watchEffect(() => {
  showProgress.value = state.currentItem.status !== 'reported'
})

watch(currentId, (value) => {
  taskId = value
  state.switched = false
  init(value)
  start()
})

const progress = computed(() => percent(state.currentItem.status || 'pending'))

watch(progress, (n, o) => {
  if ((isRunning.value && progress.value > 30) || completed.value) {
    start()
  }
})

const isRunning = computed(() => (progress.value < 100))
const completed = computed(() => (progress.value === 100))

const onlyIncludeStatic = computed(() => {
  if (state.strategy === 1) {
    return true
  } else if (state.strategy === 2) {
    return false
  } else if (!state.summary.info?.real_os_env && !(progress.value >= 40 && progress.value < 80)) {
    return true
  } else if (state.error && state.score.file_type_error) {
    return true
  } else {
    return false
  }
})

const elTableData = computed(() => {
  const data = [
    {
      name: 'analyse',
      label: t('report.report_tabs.analyse')
    }
  ]
  return data
})

function handleItemClick(item) {
  state.currentId = item.id
  state.currentItem = item
}

function percent(status) {
  switch (status) {
    case 'waiting':
    case 'pending':
      return 20
    case 'scanning':
    case 'static_dispatch_waiting':
      return 30
    case 'policy_running':
    case 'dynamic_pending':
    case 'scanning_completed':
      return 40
    case 'dynamic_dispatch_waiting':
    case 'running':
      return 60
    case 'processing':
      return 70
    case 'completed':
      return 80
    case 'reported':
      return 100
    default:
      return 100
  }
}

function getAllTaskStatus(first) {
  const tasklist = `${route.query.tasklist}`.split(',')
  Promise.all(
    tasklist.map((taskId) => {
      return getTaskUserSingle({ task_id: taskId })
    })
  ).then((res) => {
    const isFinish = res.every(
      (item) => item.task.status === 'reported' || item.task.status.includes('failed')
    )
    state.tasklist = res.map((item) => {
      return item.task
    })
    if (first) {
      state.currentId = state.tasklist[0].id
      state.currentItem = state.tasklist[0]
    } else {
      state.currentItem = state.tasklist.find((item) => item.id === state.currentId)
    }
    state.taskInfo = state.currentItem
    state.category = state.currentItem.category
    state.strategy = state.currentItem.strategy
    state.score = state.currentItem.score
    state.error = state.currentItem.error
    showProgress.value = state.currentItem.status !== 'reported'
    state.flag = true
    if (!isFinish) {
      const t = onlyIncludeStatic.value ? 2 : 15
      state.timer = setTimeout(getAllTaskStatus, 1000 * t)
    } else if (isFinish) {
      clearTimeout(state.timer)
    }
  })
}

function getStatusIconSrc(status) {
  const type = status === 'reported' ? 'success' : status.includes('failed') ? 'error' : 'pending'
  return require(`@/assets/img/svg/pending/${type}.svg`)
}

function handleTabChange(tab) {
  state.currentModule = tab
  if (route.params.type !== tab) {
    router.push({ params: { type: tab } })
  }
}

async function init(taskId) {
  try {
    await getTaskUserSingle({ task_id: taskId }).then((res) => {
      if (res.code && res.code !== 0) {
        store.commit('app/showMessage', {
          type: 'error',
          message: t('report.report_status.private'),
          duration: 1500
        })
        setTimeout(() => {
          router.push({ name: 'Home' })
        }, 800)
      } else {
        // if (res.task.status.includes('failed')) {
        //   router.push({ name: 'taskstatus', query: { tasklist: res.task.id } })
        // }
        state.taskInfo = res.task
        state.category = res.task.category
        state.strategy = res.task.strategy
        state.score = res.task.score
        state.error = res.task.error
      }
    })
  } catch (e) {}
}

async function start() {
  if (onlyIncludeStatic.value) {
    await getTaskReportOverview(taskId).then((res) => {
      if (res) {
        state.staticsearchskill.zserver = res.zserver
        state.staticsearchskill.signatures_genre = res.static_signatures_genre || []
        state.summary.threat_level = res.zserver?.threat_level || null
        state.machinelearning = res.aiengine
      }
      if (res.info) {
        state.taskOS = res.info.machine.os
        state.summary.info = res.info
        state.summary.info.download_urls = res.download_urls
        state.summary.info.target = res.target
        state.summary.params = res.params
        // window.originConfig = cloneDeep(res.params)
        state.summary.params.image = res.info.machine.image
        state.summary.taskOS = state.taskOS
        state.summary.specificCount = res?.specific_quota_count || {}
        store.commit('team/SET_SHARED_IDS', res.share_team_ids)
        state.staticsearchskill.taskOS = state.taskOS
        state.category = res.info.category
        state.machinelearning = res.aiengine
        state.behaviorfeature.signatures = res.behavior_signatures || []
        state.behaviorfeature.signatures_genre = res.behavior_signatures_genre || []
      }
    })
    // await getTaskReportZserver(taskId).then((res) => {
    //   state.staticsearchskill.zserver = res.zserver
    //   state.staticsearchskill.signatures_genre = res.static_signatures_genre || []
    //   state.staticsearchskill.signatures_genre = res.signatures_genre || []
    //   state.machinelearning = res.aiengine
    // })
    // await getTaskReportZserverInfo(taskId).then((res) => {
    //   if (res.info) {
    //     state.taskOS = res.info.machine.os
    //     state.summary.info = res.info
    //     state.summary.info.download_urls = res.download_urls
    //     state.summary.info.target = res.target
    //     state.summary.params = res.params
    //     state.summary.params.image = res.info.machine.image
    //     state.summary.taskOS = state.taskOS
    //     state.summary.specificCount = res?.specific_quota_count || {}
    //     state.staticsearchskill.taskOS = state.taskOS
    //   } else {
    //     setTimeout(() => start(), 1500)
    //   }
    // })
    if (!state.summary.info) return
    await getTaskReportTags(taskId).then((res) => {
      state.summary.info && (state.summary.info.tags = res.tags)
      state.summary.info && (state.summary.info.tagsFilter = res.filter)
    })
    await getTaskReportNetwork(taskId, { total: true, lang: isZh() ? 'zh' : 'en' }).then((res) => {
      state.networkCount = res.network
    })
  } else {
    await getTaskReportOverview(taskId).then((res) => {
      if (res.info) {
        state.taskOS = res.info.machine.os
        state.summary.info = res.info
        state.summary.threat_level = res.zserver?.threat_level || null
        state.summary.info.download_urls = res.download_urls
        state.summary.info.target = res.target
        state.summary.params = res.params
        state.summary.params.image = res.info.machine.image
        state.summary.specificCount = res.specific_quota_count
        state.summary.taskOS = state.taskOS
        store.commit('team/SET_SHARED_IDS', res.share_team_ids)
        state.staticsearchskill.zserver = res.zserver
        state.staticsearchskill.signatures_genre = res.static_signatures_genre
        state.staticsearchskill.taskOS = state.taskOS
        state.category = res.info.category
        state.machinelearning = res.aiengine
        state.behaviorfeature.signatures = res.behavior_signatures || []
        state.behaviorfeature.signatures_genre = res.behavior_signatures_genre || []
      } else {
        setTimeout(() => start(), 1500)
      }
    })
    if (!state.summary.info) return

    await getTaskReportTags(taskId).then((res) => {
      state.summary.info.tags = res.tags
      state.summary.info.tagsFilter = res.filter
    })
    await getTaskReportNetwork(taskId, { total: true, lang: isZh() ? 'zh' : 'en' }).then((res) => {
      state.networkCount = res.network
    })
    if (!isRunning.value) {
      await getTaskReportBehaviorProcess(taskId).then((res) => {
        const eventsMap = {}
        for (const item of state.events) {
          eventsMap[item.name] = isZh() ? item.zhcn : item.enus
        }
        const keyMap = Object.keys(res.events_map).length > 0 ? res.events_map : eventsMap
        state.progressanalyse.process_tree = res.processtree
        state.progressanalyse.processes = res.processes
        state.progressanalyse.tab_data =
              Object.keys(res.events_overview).length > 0 &&
              res.events_overview.map((item) => ({ ...item, name: keyMap[item.key] }))
        state.progressanalyse.taskOS = state.taskOS
      })
      await getTaskReportShots(taskId).then((res) => {
        state.behaviorscreenshot.shots = res.shots
        state.behaviorscreenshot.taskOS = state.taskOS
      })
    }
  }
  state.loading = false
  state.switched = true
}

onMounted(() => {
  init(taskId)
  setTimeout(getAllTaskStatus(true))
})

</script>

<style lang="scss" scoped>
@keyframes customRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.wrapper {
  padding: 24px 24px 0;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  .listWrap {
    width: 400px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    background-color: var(--detect-config-bg);
    padding: 0 20px;
    .detail-name {
      height: 48px;
      display: flex;
      justify-content:space-between;
      align-items: center;
      box-shadow: 0 1px 0 0 var(--color-border-2);
      .left {
        color: var(--color-text-1);
        font-weight: bold;
      }
      .right {
        color: var(--color-text-2);
        span {
          color: #00ab7a;
          margin: 0 4px;
          cursor: pointer;
        }
      }
    }
    .reports-list {
      margin-top: 16px;
      .report {
        margin-bottom: 6px;
        .report-title {
          height: 48px;
          box-sizing: border-box;
          padding: 0 12px;
          background: var(--detect-multi-item-bg);
          border-radius: 2px;
          display: flex;
          justify-content:space-between;
          align-items:center;
          &-name {
            display: flex;
            width: 30px;
            align-items:center;
            span {
              color: var(--color-text-3);
              width: 16px;
            }
            p {
              color: var(--color-text-2);
              flex: 1;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-right: 6px;
            }
          }
          &-status {
            flex: 1;
            height: 100%;
            cursor: pointer;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              display: inline-block;
              margin: 0;
              padding: 14px 0;
            }
            img {
              // width: 100%;
              height: 16px;
            }
            &.pending {
              img {
                animation: customRotate 1.5s linear infinite;
              }
            }
          }
        }
      }
    }
  }
  .content {
    flex: 1;
    background: var(--detect-config-os);
    margin-left:16px;
    overflow: hidden;
  }
  .contentWrapper {
    height: calc(100vh - 120px);
    // padding: 0 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
