<template>
  <div class="machinelearning-wrap">
    <Widgest isOpenAll widgestType="outer" :isBig="true">
      <template v-slot:title>{{$t('report.analyse.aiengine')}}</template>
      <!-- <table v-if="machineTable.length > 0" class="machine-learning-table">
        <tr>
          <th>模型名称</th>
          <th>检出结果</th>
        </tr>
        <tr v-for="item in machineTable" :key="item.name">
          <td>
            <i>
              <img src="@/assets/img/svg/machine.svg" alt="" />
            </i>
            <span>{{scanType(item.version, item.score).name}}</span>
            <el-tooltip
              :popper-append-to-body="false"
              popper-class="machinelearning-info-tips"
              :effect="$isDark() ? 'dark' : 'light'"
              :content="scanType(item.version, item.score).content"
              placement="right-end"
            >
              <i style="margin-left: 4px;"><img src="@/assets/img/svg/info3.svg" alt=""></i>
            </el-tooltip>
          </td>
          <td :class="scanType(item.version, item.score).color">
            <i v-if="item.version === 'v2'"><img :src="svgPath(item.score)" alt=""></i>
            <span>{{scanType(item.version, item.score).desc}}</span>
          </td>
        </tr>
      </table> -->
      <el-tabs class="aiengine-tabs">
        <el-tab-pane v-for="(item, key) in machineTable" :key="key">
          <template #label>
            <div class="aiengine-item">
              <div class="name">{{item.name.replace('_Detector', '')}}</div>
              <div class="score">{{item.score}}</div>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </Widgest>
  </div>
</template>

<script>
import { t } from 'app/i18n'
export default {
  name: 'MachineLearning',
  components: {

  },
  props: {
    machinelearning: [Object, Array]
  },
  data () {
    return {
    }
  },
  computed: {
    machineTable () {
      if (this.machinelearning instanceof Array) {
        return this.mergeDetectors(this.machinelearning)
      } else {
        return this.mergeDetectors(this.machinelearning.detect)
      }
    }
  },
  watch: {

  },
  methods: {
    scanType (version, score) {
      switch (version) {
        case 'v4':
          return {
            name: 'Yoda-PE',
            desc: score === -1 ? t('other.views.report.nomal') : t('other.views.report.malice'),
            color: score === -1 ? 'low' : 'high',
            content: t('other.views.report.pemsg')
          }
        case 'v2':
          return {
            name: 'Avatar',
            desc: `${t('other.views.report.threatProbability')} ${(score * 10).toFixed(1)}%`,
            color: score < 5 ? 'low' : score < 7.5 ? 'middle' : 'high',
            content: t('other.views.report.machineLearningModel')
          }
        default:
          return {
            name: version,
            desc: score,
            color: 'low',
            content: ''
          }
      }
    },
    mergeDetectors(data) {
      const detectors = data.filter(item => item.name.endsWith('_Detector'))
      const nonDetectors = data.filter(item => !item.name.endsWith('_Detector'))

      if (detectors.length === 0) {
        return nonDetectors
      }

      const scores = detectors.map(detector => parseFloat(detector.score))
      const maxScore = Math.max(...scores)
      const maxScoreDetectors = detectors.filter(detector => parseFloat(detector.score) === maxScore)

      let mergedDetector
      if (maxScore === 0) {
        mergedDetector = {
          name: t('other.views.report.family'),
          score: t('other.views.report.unRecognized'),
          zhcn_name: ''
        }
      } else {
        const randomIndex = Math.floor(Math.random() * maxScoreDetectors.length)
        mergedDetector = {
          name: t('other.views.report.family'),
          score: maxScoreDetectors[randomIndex].name.replace('_Detector', '') + ' ' + maxScore.toFixed(2)
        }
      }

      return [...nonDetectors, mergedDetector]
    },
    firstUpper (str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1)
    },
    svgPath (score) {
      const type = score < 5 ? 'low' : score < 7.5 ? 'middle' : 'high'
      return require(`@/assets/img/svg/pie-${type}.svg`)
    }
  },
  mounted () {

  }
}

</script>

<style lang="scss" scoped>
.machinelearning-wrap  {
  .machine-learning-table  {
    margin: 16px 0 18px;
    border-collapse: collapse;
    width: 100%;
    tr  {
      th, td  {
        height: 40px;
        box-sizing: border-box;
        padding: 5px 20px;
        font-size: 14px;
        border: 1px solid var(--color-border-2);
        // @include ft_color(color-main-font);
        // @include bd_color(color-sand-border);
        text-align: left;
        i  {
          margin-right: 10px;
          vertical-align: middle;
        }
        &:first-child  {
          width: 380px;
        }
        &:not(:last-child)  {
          border-right: 0;
        }
      }
      th  {
        // @include bg_color(color-sand-title-bg);
        font-weight: 500;
      }
      td  {
        font-weight: 400;
      }
      td.high  {
        // @include ft_color(color-collapse-high);
      }
      td.middle  {
        // @include ft_color(color-collapse-middle);
      }
      td.low  {
        // @include ft_color(color-collapse-low);
      }
    }
  }
  .aiengine-tabs {
    margin-top: 18px;
    margin-left: -5px;
    &:deep(.el-tabs__header) {
      .el-tabs__active-bar  {
        height: 0px;
      }
      .el-tabs__nav-wrap::after {
        height: 0;
      }
      .el-tabs__item {
        height: auto;
        padding: 5px;
        cursor: auto;
      }
      .el-tabs__nav-next, .el-tabs__nav-prev {
        top: 20px;
      }
    }
    .aiengine-item {
      min-width: 132px;
      background: var(--color-bg-3);
      color: var(--color-text-2);
      cursor: auto;
      .name {
        border: 1px solid var(--color-border-2);
        border-bottom: 0;
        border-radius: 2px 2px 0px 0px;
        text-align: center;
        font-weight: 500;
        line-height:40px;
        padding: 0 8px;
      }
      .score {
        background: var(--color-bg-4);
        border: 1px solid var(--color-border-2);
        height: 32px;
        border-radius: 0px 0px 2px 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
