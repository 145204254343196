<template>
  <div class="wrapper">
    <template v-if="props.data._type === 'dropped'">
      <div class="name">
        <copy-icon
          name="behaviorName"
          :copyText="detail.name"
          style="vertical-align: middle;"
        />
        <span>{{detail.name}}</span>
      </div>
      <div class="zserverWrap">
        <StaticTable :zserver="detail.zserver" :showLine="false"/>
      </div>
      <Widgest widgestType="outer" isOpenAll>
        <template #title>{{$t('report.static.info.file_title')}}</template>
        <SandJsonTable :noborder="true" :data="_data"></SandJsonTable>
      </Widgest>
      <Widgest widgestType="outer" isOpenAll>
        <template #title>{{$t('report.static.content.title')}}</template>
        <Authorization
          :gid="gid"
          authModule="file_content"
          :hasPayment="detail.hex_to_array.has_permission"
        >
          <template v-slot:payment>
            <SandFileContent :data="detail.hex_to_array.data" />
          </template>
        </Authorization>
      </Widgest>
    </template>
    <template v-else>
      <div class="network_content" v-for="(modules, idx) in detailModule" :key="idx">
        <div class="name" v-if="modules.title">
          <copy-icon
            name="behaviorName"
            :copyText="_data[modules.title]"
            style="vertical-align: middle;"
          />
          <span>{{_data[modules.title]}}</span>
        </div>
        <template v-if="modules.type === 'label'">
          <div class="h2">{{modules.name}}</div>
          <div class="detail">
            <div class="item" v-for="(val, key, index) in modules.map" :key="index">
              <div class="label">{{val}}</div>
              <div class="value">
                <div :class="{font: key === 'uri'}" v-html="_data[key]"></div>
                <el-tooltip :effect="$isDark() ? 'dark' : 'light'" :content="$t('ti')" placement="top">
                  <svg-icon v-if="toTiProp.includes(key)" name="sousuoti" @click="toTi(_data[key])"></svg-icon>
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
        <template v-if="modules.type === 'code'">
          <div class="h2" style="box-shadow: 0 1px 0 0 var(--color-border-2)">{{modules.name}}</div>
          <div class="code-pre" style="margin-top: 20px;">
            <pre>{{ _data[modules.prop] }}</pre>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup>
import { defineProps, computed, toRefs, reactive } from 'vue'
import { useState } from '@/hooks/useVuex'
import { useStore } from 'vuex'
import SandJsonTable from '@/components/report/sandJsonTable'
import StaticTable from '@/components/report/staticTable'
import SandFileContent from '@/components/report/sandFileContent'
import { t } from 'app/i18n'

const props = defineProps({
  data: {
    type: Object
  }
})

const state = reactive({
  toTiProp: ['src_ip', 'dst_ip', 'ip', 'area_name', 'dst', 'src']
})

const { toTiProp } = toRefs(state)

const { tiToken } = useState('user', ['tiToken'])

const deatilFields = {
  name: { name: t('report.release.filename'), copy: true },
  filepath: { name: t('report.release.filepath') },
  size: {
    name: t('report.release.filesize'),
    formatter: (value) => {
      return `${value} bytes`
    }
  },
  qex_type: { name: t('report.release.type') },
  type: { name: t('report.release.format') },
  md5: { name: 'MD5', copy: true, toTI: true },
  sha1: { name: 'SHA1', copy: true, toTI: true },
  sha256: { name: 'SHA256', copy: true, toTI: true },
  crc32: { name: 'CRC32' },
  ssdeep: { name: 'SSDeep' }
}

const $tt = v => t(`report.network.${v}`)

const detailModule = computed(() => {
  switch (props.data._type) {
    case 'hosts':
      return [{
        title: 'ip',
        ipProp: 'ip',
        name: $tt('hosts.info'),
        type: 'label',
        map: {
          ip: $tt('hosts.ip'),
          country: $tt('hosts.country'),
          city: $tt('hosts.city'),
          isp: 'ISP'
        }
      }]
    case 'domains':
      return [
        {
          title: 'area_name',
          ipProp: 'area_name',
          name: $tt('dns.info'),
          type: 'label',
          map: {
            area_name: $tt('dns.area_name'),
            response: $tt('dns.response'),
            analyse: $tt('dns.analyse')
          }
        }
      ]
    case 'http':
    case 'https':
      return [
        {
          title: 'uri',
          name: $tt('http.info'),
          type: 'label',
          map: {
            uri: 'URI'
          }
        },
        {
          name: $tt('http.content'),
          type: 'code',
          prop: 'data'
        }
      ]
  }
  return ''
})

const _data = computed(() => {
  if (props.data._type === 'dropped') {
    const columnData = Object.keys(deatilFields).map(item => ({
      prop: item,
      label: deatilFields[item].name,
      copy: deatilFields[item].copy,
      toTI: deatilFields[item].toTI
    }))
    const tableData = Object.assign({}, props.data, { size: `${props.data.size} bytes` })
    return { columnData, tableData, keyWidth: '100px' }
  } else if (props.data._type === 'domains') {
    return {
      area_name: props.data.domain,
      response: props.data.answers.map(item => `${item.type} ${item.data}`).join('\n'),
      analyse: props.data.lookups
    }
  }
  return props.data
})
const store = useStore()
const gid = store.getters.gid
const detail = computed(() => _data.value.tableData)

function toTi(params) {
  window.open(`https://ti.360.net/#/detailpage/searchresult?query=${params}&rand=${Math.random()}&sign=${tiToken.value}&from=ata`)
}
</script>
<style lang="scss" scoped>
.name {
  border: 1px solid var(--color-border-2);
  height: 46px;
  line-height: 46px;
  color: var(--color-text-2);
  padding: 0 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  svg {
    color: var(--color-text-3);
  }
  span:nth-child(2) {
    margin-left: 2px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.zserverWrap {
  padding: 15px 0 0;
}
.network_content {
  .h2 {
    font-size: 16px;
    color: var(--color-text-1);
    height: 48px;
    line-height: 48px;
    box-shadow: 0 1px 0 0 var(--color-border-2);
  }
  .detail {
    margin-top: 20px;
    margin-bottom: 16px;
    .item {
      display: flex;
      margin-top: 10px;
      .label {
        font-size: 14px;
        color: var(--color-text-3);
        font-weight: 500;
        width: 80px;
        margin-right: 40px;
        line-height: 22px;
      }
      .value {
        flex: 1;
        font-size: 14px;
        color: var(--color-text-2);
        display: flex;
        align-items: center;
        svg {
          margin-left: 6px;
          font-size: 16px;
          color:var(--color-unkown-text);
          cursor:pointer;
          &:hover {
            color: #00ab7a;
          }
        }
        div {
          white-space: pre-wrap;
          line-height: 1.6;
        }
        .font {
          word-break: break-all;
        }
      }
    }
  }
  .code-pre {
    overflow-y: auto;
    padding: 0 16px;
    height: 200px;
    border: 1px solid var(--color-border-2);
    border-radius: 2px;
    color: var( --color-unkown-text);
    background: var(--color-bg-3);
    pre {
      white-space: pre-wrap;
      line-height: 20px;
      // white-space: pre-line;
      word-break: break-all;
      font-size: 14px;
      // font-family: 'CONSOLA';
    }
  }
}
</style>
