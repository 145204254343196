<template>
  <div class="configDetail">
    <!-- <img style="cursor: pointer;" @click="dialogVisible = true" src="@/assets/images/svg/detail.svg" title="查看详情"> -->
    <div class="configDetail-click" @click="dialogVisible = true">{{$t('report.overview.detail.config.label')}}</div>
    <el-dialog :title="$t('report.overview.detail.config.title')" @close="dialogVisible = false" :model-value="dialogVisible" width="800px">
      <sand-tab :data="tabData" @change="tagChange"></sand-tab>
      <div class="configDetail-content">
        <div class="configDetail-task" v-show="active === 'task'">
          <div class="configDetail-task__block" v-for="(block, index) in taskData" :key="index">
            <div v-if="block.type === type || !block.type">
              <span class="configDetail-task__block--label">{{ block.label }}</span>
              <div class="configDetail-task__item" v-for="item in block.children" :key="item.label">
                <div v-if="item.show !== false">
                  <span class="configDetail-task__item--label">{{ item.label }}：</span>
                  <span class="configDetail-task__item--value">{{ item.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="configDetail-app configDetail-tab" v-show="active === 'app'">
          <div v-if="software.length === 0" style="text-align: center;">
            {{$t('nodata')}}
          </div>
          <div
            v-else
            class="configDetail-app__item configDetail-tab__item"
            v-for="item in software"
            :key="item"
          >
            {{ item }}
          </div>
        </div>
        <div class="configDetail-install configDetail-tab" v-show="active === 'install'">
          <div v-if="install.length === 0" style="text-align: center;">
            {{$t('nodata')}}
          </div>
          <div
            v-else
            class="configDetail-install__item configDetail-tab__item"
            v-for="(item, index) in install"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div class="configDetail-dependent configDetail-tab" v-show="active === 'dependent'">
         <div v-if="dependent.length === 0" style="text-align: center;">
            {{$t('nodata')}}
          </div>
          <div
            v-else
            class="configDetail-dependent__item configDetail-tab__item"
            v-for="(item, index) in dependent"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import sandTab from './sandTab'
import { getImage } from 'services/report.js'
import { getImagesTimezone, getImagesRegion } from 'services/detection'
import { t, isZh } from 'app/i18n'
export default {
  components: {
    sandTab
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    os: {
      type: String,
      default: 'windows'
    },
    type: {
      type: String,
      default: 'file'
    },
    fileType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tabData: [
        {
          label: t('report.overview.detail.config.task.title'),
          name: 'task'
        },
        {
          label: t('report.overview.detail.config.app'),
          name: 'app'
        },
        {
          label: t('report.overview.detail.config.install'),
          name: 'install'
        },
        {
          label: t('report.overview.detail.config.dependent'),
          name: 'dependent'
        }
      ],
      active: 'task',
      imageData: {},
      wkdirOption: {
        desktop: t('config.dynamic.runpath.options.desktop'),
        document: t('config.dynamic.runpath.options.document'),
        user: t('config.dynamic.runpath.options.user'),
        download: t('config.dynamic.runpath.options.download'),
        appdata: t('config.dynamic.runpath.options.appdata'),
        temp: t('config.dynamic.runpath.options.temp'),
        windows: t('config.dynamic.runpath.options.windows'),
        rootdir: t('config.dynamic.runpath.options.rootdir')
      },
      emailerOption: {
        outlook: t('config.dynamic.emailer.options.outlook'),
        extract: t('config.dynamic.emailer.options.execute_attach')
      },
      officeOption: {
        mso: 'Microsoft Office',
        wps: 'WPS'
      },
      browserOption: {
        ie: 'Internet Explorer',
        firefox: 'Mozilla Firefox',
        chrome: 'Google Chrome'
      },
      langMap: {
        'zh-cn': t('config.basic.lang.map.zh-cn'),
        'en-us': t('config.basic.lang.map.en-us'),
        'ko-kr': t('config.basic.lang.map.ko-kr'),
        any: t('config.basic.lang.map.any')
      },
      strategyMap: {
        0: t('config.basic.strategy.intelligent'),
        1: t('config.basic.strategy.enforce'),
        2: t('config.basic.strategy.close')
      },
      regionOption: {},
      timezoneOption: {},
      dialogVisible: false
    }
  },
  computed: {
    $tc() {
      return (v) => {
        return t(`report.overview.detail.config.task.${v}`)
      }
    },
    software() {
      if (!this.imageData.software) return []
      return this.imageData.software.map((item) => `${item.name} ${item.major} (${item.build})`)
    },
    install() {
      if (!this.imageData.update) return []
      return this.imageData.update
    },
    dependent() {
      if (!this.imageData.dependency) return []
      return this.imageData.dependency.map((item) => `${item.name} ${item.major} (${item.build})`)
    },
    taskData() {
      if (this.os === 'linux') {
        return [
          {
            label: this.$tc('basic.label'),
            children: [
              {
                label: this.$tc('basic.children[0].label'),
                alias: 'strategy',
                value: this.$tc('basic.children[0].default'),
                getValue: (data) => this.strategyMap[data.strategy]
              },
              {
                label: this.$tc('basic.children[1].label'),
                alias: 'machine',
                value: this.$tc('basic.children[1].default'),
                getValue: (data) => data.machine
              },
              {
                label: this.$tc('basic.children[2].label'),
                alias: 'lang',
                value: this.$tc('basic.children[2].default'),
                getValue: (data) => {
                  // return this.langMap[data.lang]
                  return t(`config.basic.lang.map.${data.lang}`)
                }
              }
            ]
          },
          {
            label: this.$tc('network.label'),
            children: [
              {
                label: this.$tc('network.children[0].label'),
                alias: 'internet',
                value: this.$tc('off'),
                getValue: (data) => (data.internet ? this.$tc('on') : this.$tc('off'))
              }
            ]
          }
        ]
      }
      if (this.os === 'android') {
        return [
          {
            label: this.$tc('basic.label'),
            children: [
              {
                label: this.$tc('basic.children[0].label'),
                alias: 'strategy',
                value: this.$tc('basic.children[0].default'),
                getValue: (data) => this.strategyMap[data.strategy]
              },
              {
                label: this.$tc('basic.children[1].label'),
                alias: 'machine',
                value: this.$tc('basic.children[1].default'),
                getValue: (data) => data.machine
              },
              {
                label: this.$tc('basic.children[2].label'),
                alias: 'lang',
                value: this.$tc('basic.children[2].default'),
                getValue: (data) => {
                  return this.langMap[data.lang]
                }
              }
            ]
          },
          {
            label: this.$tc('strategy.label'),
            children: [
              {
                label: this.$tc('strategy.children[0].label'),
                alias: 'timeout',
                value: this.$tc('strategy.children[0].default'),
                getValue: (data) => data.timeout + this.$tc('second')
              },
              {
                label: this.$tc('strategy.children[1].label'),
                alias: 'human',
                value: this.$tc('off'),
                getValue: (data) => (data.human ? this.$tc('on') : this.$tc('off'))
              }
            ]
          },
          {
            label: this.$tc('network.label'),
            children: [
              {
                label: this.$tc('network.children[0].label'),
                alias: 'internet',
                value: this.$tc('off'),
                getValue: (data) => (data.internet ? this.$tc('on') : this.$tc('off'))
              },
              {
                label: this.$tc('network.children[1].label'),
                alias: 'decrypt_https',
                value: this.$tc('off'),
                getValue: (data) => (data.decrypt_https ? this.$tc('on') : this.$tc('off')),
                show: this.os !== 'linux'
              }
            ]
          }
        ]
      }
      return [
        {
          label: this.$tc('basic.label'),
          children: [
            {
              label: this.$tc('basic.children[0].label'),
              alias: 'strategy',
              value: this.$tc('basic.children[0].default'),
              getValue: (data) => this.strategyMap[data.strategy]
            },
            {
              label: this.$tc('basic.children[1].label'),
              alias: 'machine',
              value: this.$tc('basic.children[1].default'),
              getValue: (data) => data.machine
            },
            {
              label: this.$tc('basic.children[2].label'),
              alias: 'lang',
              value: this.$tc('basic.children[2].default'),
              getValue: (data) => {
                // return this.langMap[data.lang]
                return t(`config.basic.lang.map.${data.lang}`)
              }
            }
          ]
        },
        {
          label: this.$tc('dynamic.label'),
          // type: 'url',
          children: [
            {
              label: this.$tc('dynamic.children[0].label'),
              alias: 'browser',
              value: 'Internet Explorer',
              getValue: (data) => this.browserOption[data.browser] || '-',
              show: this.type === 'url'
            },
            {
              label: this.$tc('dynamic.children[1].label'),
              alias: 'custom_detect_type',
              value: this.$tc('dynamic.children[1].default'),
              getValue: (data) => data.custom_detect_type,
              show: this.type !== 'url'
            },
            {
              label: this.$tc('dynamic.children[2].label'),
              alias: 'cmd',
              value: '-',
              getValue: (data) => data.cmd ? data.cmd : '-',
              show: this.showItem('cmd')
            },
            {
              label: this.$tc('dynamic.children[3].label'),
              alias: 'function',
              value: '-',
              // getValue: (data) => `函数名：${data.function}  参数：${data.arguments}`,
              getValue: (data) => `${this.$tc('dynamic.children[3].function')}：${data.function}  ${this.$tc('dynamic.children[3].params')}：${data.arguments}`,
              show: this.showItem('function')
            },
            {
              label: this.$tc('dynamic.children[4].label'),
              alias: 'pwd_to_zip',
              value: '-',
              getValue: (data) => data.pwd_to_zip ? data.pwd_to_zip : '-',
              show: this.showItem('pwd_to_zip')
            },
            {
              label: this.$tc('dynamic.children[5].label'),
              // 由于这一个是多个参数共同决定，所以找一个必有字段让其必须存在，然后自定义方法判断对应显示内容
              alias: 'machine',
              value: '-',
              getValue: (data) => this.getUnzipName(data),
              show: this.showItem('in_zip')
            },
            {
              label: this.$tc('dynamic.children[6].label'),
              alias: 'pwd_to_mso',
              value: '-',
              getValue: (data) => data.pwd_to_mso ? data.pwd_to_mso : '-',
              show: this.showItem('pwd_to_mso')
            },
            {
              label: this.$tc('dynamic.children[7].label'),
              alias: 'office',
              value: '-',
              getValue: (data) => data.office && this.officeOption[data.office] ? this.officeOption[data.office] : '-',
              show: this.showItem('office')
            },
            {
              label: this.$tc('dynamic.children[8].label'),
              alias: 'emailer',
              value: '-',
              getValue: (data) => data.emailer && this.emailerOption[data.emailer] ? this.emailerOption[data.emailer] : '-',
              show: this.showItem('emailer')
            },
            {
              label: this.$tc('dynamic.children[9].label'),
              alias: 'name_forced',
              value: '-',
              getValue: (data) => data.name_forced,
              show: this.type !== 'url'
            },
            {
              label: this.$tc('dynamic.children[10].label'),
              alias: 'ext_forced',
              value: '-',
              getValue: (data) => data.ext_forced,
              show: this.type !== 'url'
            },
            {
              label: this.$tc('dynamic.children[11].label'),
              alias: 'runpath',
              value: this.$tc('dynamic.children[11].default'),
              // getValue: (data) => this.wkdirOption[data.runpath],
              getValue: (data) => t(`config.dynamic.runpath.options.${data.runpath}`),
              show: this.type !== 'url'
            }
          ]
        },
        {
          label: this.$tc('strategy.label'),
          children: [
            {
              label: this.$tc('strategy.children[0].label'),
              alias: 'timeout',
              value: this.$tc('strategy.children[0].default'),
              getValue: (data) => data.timeout + this.$tc('second')
            },
            {
              label: this.$tc('strategy.children[2].label'),
              alias: 'human',
              value: this.$tc('off'),
              getValue: (data) => (data.human ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[3].label'),
              alias: 'human_popbox',
              value: this.$tc('off'),
              getValue: (data) => (data.human_popbox ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[4].label'),
              alias: 'human_scroll',
              value: this.$tc('off'),
              getValue: (data) => (data.human_scroll ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[5].label'),
              alias: 'human_clipbd',
              value: this.$tc('off'),
              getValue: (data) => (data.human_clipbd ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[6].label'),
              alias: 'human_curpos',
              value: this.$tc('off'),
              getValue: (data) => (data.human_curpos ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[7].label'),
              alias: 'human_autoin',
              value: this.$tc('off'),
              getValue: (data) => (data.human_autoin ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[8].label'),
              alias: 'fast_detect',
              value: this.$tc('off'),
              getValue: (data) => (data.fast_detect ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[9].label'),
              alias: 'fake_reboot',
              value: this.$tc('off'),
              getValue: (data) => (data.fake_reboot ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[10].label'),
              alias: 'active_reboot',
              value: this.$tc('off'),
              getValue: (data) => (data.active_reboot ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[11].label'),
              alias: 'pwd_forced',
              value: this.$tc('off'),
              getValue: (data) => (data.pwd_forced ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[12].label'),
              alias: 'timezone',
              value: this.$tc('auto_setting'),
              getValue: (data) => this.timezoneOption[data.timezone]
            },
            {
              label: this.$tc('strategy.children[13].label'),
              alias: 'geo',
              value: this.$tc('auto_setting'),
              getValue: (data) => this.regionOption[data.geo]
            },
            {
              label: this.$tc('strategy.children[14].label'),
              alias: 'custom_date',
              value: this.$tc('auto_setting'),
              getValue: (data) => `${data.custom_date} ${data.custom_time} `
            },
            {
              label: this.$tc('strategy.children[15].label'),
              alias: 'env_var',
              value: '-',
              getValue: (data) => {
                if (data.env_var.length > 0) {
                  return data.env_var.join(';')
                }
                return '-'
              }
            },
            {
              label: this.$tc('strategy.children[16].label'),
              alias: 'fake_av',
              value: this.$tc('off'),
              getValue: (data) => (data.fake_av ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('strategy.children[17].label'),
              alias: 'hook_sleep',
              value: this.$tc('on'),
              getValue: (data) => (data.hook_sleep ? this.$tc('on') : this.$tc('off'))
            }
          ]
        },
        {
          label: this.$tc('network.label'),
          children: [
            {
              label: this.$tc('network.children[0].label'),
              alias: 'internet',
              value: this.$tc('off'),
              getValue: (data) => (data.internet ? this.$tc('on') : this.$tc('off'))
            },
            {
              label: this.$tc('network.children[1].label'),
              alias: 'decrypt_https',
              value: this.$tc('off'),
              getValue: (data) => (data.decrypt_https ? this.$tc('on') : this.$tc('off')),
              show: this.os !== 'linux'
            }
          ]
        }
      ]
    }
  },
  watch: {
    'data.image'() {
      if (this.data.image) {
        this.getData()
      }
    },
    taskData(v) {
      this.resetTaskData()
    }
  },
  mounted() {
    if (this.data.image) {
      this.getData()
    }
  },
  methods: {
    getUnzipName(data) {
      if (data.file_in_zip) {
        return `${this.$tc('file_in_zip')}${data.file_in_zip}`
      }
      if (data.md5_in_zip) {
        return `${this.$tc('md5_in_zip')}${data.md5_in_zip}`
      }
      if (data.type_in_zip) {
        return `${this.$tc('type_in_zip')}${data.type_in_zip}`
      }
      return '-'
    },
    showItem(key) {
      if (this.type === 'url') {
        return false
      }
      if (this.data.custom_detect_type) {
        // 命令行
        if (key === 'cmd') {
          if (this.data.custom_detect_type === 'exe') {
            return true
          }
        }
        // 动态库参数
        if (key === 'function') {
          if (this.data.custom_detect_type === 'dll') {
            return true
          }
        }
        // 解压密码
        if (key === 'pwd_to_zip') {
          if (this.data.custom_detect_type === 'zip' || this.data.custom_detect_type === 'rar' || this.data.custom_detect_type === '7z') {
            return true
          }
        }
        // 指定压缩文件中的子文件
        if (key === 'in_zip') {
          if (this.data.custom_detect_type === 'zip' || this.data.custom_detect_type === 'rar' || this.data.custom_detect_type === '7z') {
            return true
          }
        }
        // 文档密码
        if (key === 'pwd_to_mso') {
          if (this.data.custom_detect_type === 'pdf' || this.data.custom_detect_type === 'doc' || this.data.custom_detect_type === 'xls' || this.data.custom_detect_type === 'ppt' || this.data.custom_detect_type === 'rtf') {
            return true
          }
        }
        // 指定文档打开方式
        if (key === 'office') {
          if (this.data.custom_detect_type === 'doc' || this.data.custom_detect_type === 'xls' || this.data.custom_detect_type === 'ppt' || this.data.custom_detect_type === 'rtf') {
            return true
          }
        }
        // 邮件打开方式
        if (key === 'emailer') {
          if (this.data.custom_detect_type === 'eml' || this.data.custom_detect_type === 'msg') {
            return true
          }
        }
      } else {
        // 命令行
        if (key === 'cmd') {
          if (['exe32', 'exe64'].indexOf(this.fileType) > -1) {
            return true
          }
        }
        // 动态库参数
        if (key === 'function') {
          if (['dll32', 'dll64'].indexOf(this.fileType) > -1) {
            return true
          }
        }
        // 解压密码
        if (key === 'pwd_to_zip') {
          if (['zip', 'rar', '7z', 'xz', 'xar', 'tar', 'nsis', 'gz', 'cab', 'bzip2'].indexOf(this.fileType) > -1) {
            return true
          }
        }
        // 指定压缩文件中的子文件
        if (key === 'in_zip') {
          if (['zip', 'rar', '7z', 'xz', 'xar', 'tar', 'nsis', 'gz', 'cab', 'bzip2'].indexOf(this.fileType) > -1) {
            return true
          }
        }
        // 文档密码
        if (key === 'pwd_to_mso') {
          if (['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'pptm', 'rtf', 'ole', 'olex', 'olet', 'odt', 'pdf'].indexOf(this.fileType) > -1) {
            return true
          }
        }
        // 指定文档打开方式
        if (key === 'office') {
          if (['doc', 'docx', 'docm', 'xls', 'xlsx', 'xlsm', 'ppt', 'pptx', 'pptm', 'rtf', 'ole', 'olex', 'olet', 'odt'].indexOf(this.fileType) > -1) {
            return true
          }
        }
        // 邮件打开方式
        if (key === 'emailer') {
          if (['eml', 'msg'].indexOf(this.fileType) > -1) {
            return true
          }
        }
      }
      return false
    },
    getData() {
      Promise.all([
        this.getImageData(this.data.machine || this.data.image),
        this.getImagesRegionOption(),
        this.getImagesTimezoneOption()
      ]).then((res) => {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.lang = this.imageData.lang
        // eslint-disable-next-line vue/no-mutating-props
        this.data.machine = `${this.imageData.os} ${this.imageData.major} ${this.imageData.arch}${this.$tc('bit')} ${this.imageData.build}`
        this.resetTaskData()
      })
    },
    tagChange(name) {
      this.active = name
    },
    getImageData(image) {
      return getImage(image).then((data) => {
        this.imageData = data
      })
    },
    getImagesRegionOption() {
      return getImagesRegion({
        lang: isZh() ? 'zh' : 'en'
      }).then((res) => {
        this.regionOption = {}
        for (const key in res) {
          this.regionOption[res[key][0]] = res[key][1]
        }
      })
    },
    getImagesTimezoneOption() {
      return getImagesTimezone({
        lang: isZh() ? 'zh' : 'en'
      }).then((res) => {
        this.timezoneOption = {}
        for (const key in res) {
          this.timezoneOption[res[key][0]] = res[key][1]
        }
      })
    },
    resetTaskData() {
      this.taskData.forEach((i) => {
        i.children.forEach((j) => {
          // if (j.alias === 'user_agent') {
          //   j.show = Boolean(this.data.user_agent)
          // }
          // if (j.alias === 'browser') {
          //   j.show = !this.data.user_agent
          // }
          if (j.alias) {
            if (this.data[j.alias] !== undefined) {
              j.value = j.getValue(this.data)
            }
          } else {
            j.value = j.getValue(this.data) || j.value
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
[lang='en'] {
  .configDetail-task__item--label {
    width: 300px;
  }
  .configDetail-task__item--value {
    width: 300px;
  }
}
.configDetail  {
  display: inline-block;
  &-click  {
    cursor: pointer;
    color: #00ab7a;
    &:hover  {
      filter: brightness(1.2);
    }
  }
  & :deep(.el-dialog) {
    // @include bg_color(color-main-box-bx);
    // & .el-dialog__title  {
    //   @include ft_color(color-main-white);
    // }
    & .el-dialog__body  {
      padding: 10px 20px;
    }
  }
  &-content  {
    height: 450px;
    padding: 10px 20px 20px;
    // @include ft_color(color-main-font);
    overflow: auto;
    // @include scroll_bar();
  }
  &-task  {
    &__block  {
      margin-top: 30px;
      &:first-child  {
        margin-top: 0px;
        & .configDetail-task__block--label  {
          margin-top: 0px;
        }
      }
      &--label  {
        display: inline-block;
        position: relative;
        &:before  {
          content: ' ';
          width: 3px;
          height: 12px;
          background: #00ab7a;
          position: absolute;
          left: -10px;
          top: 9px;
        }
      }
    }
    &__item  {
      padding-left: 20px;
      margin-top: 20px;
      &--label  {
        display: inline-block;
        width: 180px;
        vertical-align: top;
        font-family: PingFang SC;
        font-size: 14px;
        color: var(--color-text-2);
        opacity: 0.6;
      }
      &--value  {
        display: inline-block;
        width: 500px;
      }
    }
  }
  &-tab  {
    &__item  {
      height: 20px;
      line-height: 20px;
      margin-top: 20px;
      &:first-child  {
        margin-top: 0px;
      }
    }
  }
}
</style>
